const base_route = import('@/view/index.vue')
export default [
  {
    path: '/',
    component: () => base_route,
    children: [
      {
        path: 'usercenter',
        component: () => import('@/view/pages/userCenter/index.vue'),
        children: [
          {
            path: '',
            name: 'usercenter',
            meta: {
              title: '账号管理-至臻家居',
              type: 'usercenter',
              login: true,
              keepAlive: false
            },
            component: () => import('@/view/pages/userCenter/page/account.vue')
          },
          {
            path: 'collect',
            name: 'collect',
            meta: {
              title: '收藏-至臻家居',
              type: 'collect',
              login: true,
              keepAlive: false
            },
            component: () => import('@/view/pages/userCenter/page/collect.vue')
          },
          {
            path: 'myspace',
            name: 'myspace',
            meta: {
              title: '收藏-至臻家居',
              type: 'myspace',
              login: true,
              keepAlive: false
            },
            component: () => import('@/view/pages/userCenter/page/mySpace.vue')
          }
        ]
      }
    ]
  }
]
