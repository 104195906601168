<template>
  <div class="main">
    <navroll :distance="distance" />
    <header-nav />
    <slot />
    <footer-view />
  </div>
</template>

<script>
import navroll from '@/components/public/navroll.vue' // 滚动漂浮导航
import headerNav from '@/components/public/headerNav.vue' // 顶部导航
import footerView from '@/components/public/footer.vue' // 公共底部
export default {
  components: {
    navroll,
    headerNav,
    footerView
  },
  props: {
    distance: {
      type: Number,
      default: 400
    }
  }
}
</script>
