import { delCookie, LocalStorageUtils } from '@/utils'
import { userInfoApi } from '@/assets/api/user.js'
import { flieDownReport } from '@/assets/api/index.js'
export default {
  fedLogout({ commit }) {
    return new Promise(resolve => {
      console.log('logout')
      delCookie('_zz__auth')
      commit('USER_INFO', {})
      commit('LOGIN_USER', {})
      LocalStorageUtils.remove('login_user')
      LocalStorageUtils.remove('user_info')
      resolve()
    })
  },
  getUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      userInfoApi().then(response => {
        commit('USER_INFO', response)
        commit('LOGIN_USER', {
          ...state.login_user,
          avatar: response.avatar,
          apply_step: response.apply_step,
          tel: response.tel,
          type: response.type,
          name: response.name,
          id: response.id
        })
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  openLoginView({ commit }) {
    commit('MASK_SHOW', true)
    commit('LOGIN_SHOW', true)
  },
  dowReport({ commit }, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      flieDownReport(data).then(res => resolve(res)).catch(err => reject(err))
    })
  }
}
