/** @type {*} 产品列表+搜索 */
const base_route = import('@/view/index.vue')
export default [
  {
    path: '/spot',
    component: () => base_route,
    children: [
      {
        path: '',
        name: 'spot',
        meta: {
          title: '平台现货-至臻居家',
          type: 'spot',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/spot.vue')
      },
      {
        path: ':id',
        name: 'spot details',
        meta: {
          title: '平台现货-至臻居家',
          type: 'item',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/spot.vue')
      }
    ]
  }
]
