// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
// 按需引入element-ui
// import 'element-ui/lib/theme-chalk/index.css' // element样式
import element from "./utils/elmentui.js"; // 组件
import "element-ui/lib/theme-chalk/base.css";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import "./assets/css/tailwind.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper); // 引入swiper
Vue.use(element, { size: "small", zIndex: 2000 }); // 组件默认大小和层次级
// baidu star
import ba from "vue-ba";
Vue.use(ba, "2a09b02bd1abebdc434c64b9ea896699");
Vue.use(ba, { siteId: "2a09b02bd1abebdc434c64b9ea896699" });
// baidu end

import publicMain from "@/components/public/main.vue"; // 注册公共组件头部 悬浮导航 footer
Vue.component("public-main", publicMain);

import App from "./App";
import router from "./router";
import store from "./store";

Vue.component(CollapseTransition.name, CollapseTransition);

import "babel-polyfill";
import Es6Promise from "es6-promise";
require("es6-promise").polyfill();
Es6Promise.polyfill();
Vue.prototype.$static_domain = process.env.VUE_APP_STATIC_URL;
Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
});
