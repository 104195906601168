import { mapMutations, mapGetters } from 'vuex'
import { forbidScrollThrough, deepClone } from '@/utils/index.js'
export const mymixins = {
  components: {
  },
  computed: {
    ...mapGetters('pc', {
      mask_show: 'mask_show',
      login_show: 'login_show',
      identity_show: 'identity_show',
      apply_show: 'apply_show',
      release_show: 'release_show',
      user_info: 'user_info',
      login_user: 'login_user',
      is_apply: 'is_apply'
    }),
    params() {
      return this.$route.params
    },
    query() {
      return this.$route.query
    },
    islogin() {
      if (this.login_user.id) return true
      return false
    }
  },
  methods: {
    deepClone,
    ...mapMutations({
      MASK_SHOW: 'pc/MASK_SHOW',
      LOGIN_SHOW: 'pc/LOGIN_SHOW',
      IDENTITY_SHOW: 'pc/IDENTITY_SHOW',
      APPLY_SHOW: 'pc/APPLY_SHOW',
      RELEASE_SHOW: 'pc/RELEASE_SHOW',
      USER_INFO: 'pc/USER_INFO',
      LOGIN_USER: 'pc/LOGIN_USER',
      IS_APPLY: 'pc/IS_APPLY',
      COMPETITION_LIST: 'pc/COMPETITION_LIST'
    }),
    arrAyMerge(arr, key) {
      let new_array = []
      for (let i = 0; i < arr.length; i++) {
        new_array = [...new_array, ...arr[i][key]]
      }
      return new_array
    },
    loginViewShow(status) { // 登录窗口
      this.MASK_SHOW(status)
      this.LOGIN_SHOW(status)
      // forbidScrollThrough(status)
    },
    identityShow(status) { // 身份选择窗口
      this.MASK_SHOW(status)
      this.IDENTITY_SHOW(status)
      forbidScrollThrough(status)
    },
    applyShow(status) { // 申请设计师窗口
      this.MASK_SHOW(status)
      this.APPLY_SHOW(status)
      forbidScrollThrough(status)
    },
    releaseShow(status) { // 发布窗口
      this.MASK_SHOW(status)
      this.RELEASE_SHOW(status)
      forbidScrollThrough(status)
    },
    getFileFromData(file) { // 文件上传
      const fileFormData = new FormData()
      fileFormData.append('file', file.file)
      return fileFormData
    }
  }
}
