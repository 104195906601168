/** @type {*} 产品列表+搜索 */
const base_route = import('@/view/index.vue')
export default [
  {
    path: '/product',
    component: () => base_route,
    children: [
      {
        path: '',
        name: 'product',
        meta: {
          title: '产品-至臻居家',
          type: 'product',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/product')
      },
      {
        path: ':id',
        name: 'Product details',
        meta: {
          title: '详情-产品-至臻居家',
          type: 'item',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/productDetails.vue')
      }
    ]
  }
]
