<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        datas="404"
      />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/**自定义tost背景颜色和文字颜色 */
/* .van-toast {
  background: #dddcdd;
  color: #191b19;
}
.van-toast .van-loading__circular {
  color: #191b19;
} */
html,
body {
  background: linear-gradient(270deg, #f8f4ed 0%, #ffffff 100%);
}
</style>
<style lang="less">
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
</style>
