import request from '@/utils/request'

/**
 *
 * 获取验证码
 * @export
 * @param {
 *  tel
 * } data
 * @return {*}
 */
export function getCodeApi(data) {
  return request.get(`/v1/user/login/code`, { params: data })
}

/**
 *手机号登录
 *
 * @export
 * @param {
 *  tel
 *  code
 * } data
 * @return {*}
 */
export function telLoginApi(data) {
  return request.post(`/v1/user/login/tel`, data)
}

/**
 * 获取用户信息
 *
 * @export
 * @return {*}
 */
export function userInfoApi() {
  return request.get(`/v1/user/info`)
}

/**
 * 获取用户信息
 *
 * @export
 * @return {*}
 */
export function userInfoUpdateApi(data) {
  return request.post(`/v1/user/update`, data)
}

/**
 *设计师申请
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function designApply(data) {
  return request.post(`/v1/user/design/apply`, data)
}

/**
 *文件上传
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function fileUploadApi(data) {
  return request.post(`/v1/file`, data, { timeout: 1000 * 60 * 10 })
}

/**
 *新建/编辑 合集
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function collectionApi(data, url) {
  return request.post(`/v1/design/collection/${data.id || ''}${url || ''}`, data)
}

/**
 *发布作品-合集列表
 *
 * @export
 * @return {*}
 */
export function collectionListApi() {
  return request.get(`/v1/design/collection/list`)
}

/**
 *
 *发布作品
 * @export
 * @param {*} data
 * @return {*}
 */
export function publishWorks(data) {
  return request.post(`/v1/user/design`, data)
}

/**
 *设计师申请第一步
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function applyDesignOne(data) {
  return request.post(`/v1/user/design/apply/step/1`, data)
}
/**
 *设计师申请第二部
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function applyDesignTwo(data) {
  return request.post(`/v1/user/design/apply/step/2`, data)
}

/**
 *
 *设计师作品列表
 * @export
 * @param {
 * limit
 * page
 * user_id
 * search
 * collection_id
 * } data
 * @return {*}
 */
export function designworksListApi(data) {
  return request.get(`v1/user/design/list`, { params: data })
}

/**
 *设计师作品详情
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function designworksDetailsApi(data) {
  return request.get(`/v1/user/design/${data.id}`)
}

/**
 *产品收藏
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function pdCollectApi(data) {
  return request.post(`/v1/product/${data.id}/collect`)
}

/**
 *取消产品收藏
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function cancelPdCollectApi(data) {
  return request.post(`/v1/product/${data.id}/collect/cancel`)
}

/**
 *产品收藏列表
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function pdCollectListApi(data) {
  return request.get(`/v1/product/collect`, { params: data })
}

/**
 *判断是否收藏产品
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function isPdCollect(data) {
  return request.get(`/v1/product/${data.id}/collect/check`)
}

/**
 *作品收藏（案例收藏）
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function worksCollectApi(data) {
  return request.post(`/v1/user/design/${data.id}/collect`)
}

/**
 *取消作品收藏（取消案例收藏）
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function cancelWorksCollectApi(data) {
  return request.post(`/v1/user/design/${data.id}/collect/cancel`)
}

/**
 *作品收藏列表（案例收藏列表）
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function worksCollectListApi(data) {
  return request.get(`/v1/user/design/collect`, { params: data })
}

/**
 * 检查是否收藏作品
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function isWorksCollect(data) {
  return request.get(`/v1/user/design/${data.id}/collect/check`)
}

/**
 *
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function designInfo(data) { // 设计师信息
  return request.get(`/v1/user/design/designer/${data.id}`)
}

/**
 *获取合集列表（个人空间 、 设计师主页）
 *
 * @export
 */
export function getCollectionApi(data) {
  return request.get(`/v1/user/design/collection/space`, { params: data })
}

/**
 *
 *添加询价单
 * @export
 * @param {*
 * product_id: 商品id
 * id: 询价单ID。可空：空表示创建新的记录；非空表示：添加新的产品
 * } data
 * @return {*}
 */
export function addEnquiryApi(data) {
  return request.post(`/v1/product/enquiry/cart`, data)
}

/**
 *
 *提交询价单
 * @export
 * @param {*} data
 * @return {*}
 */
export function submitEnquiryApi(data) {
  return request.post(`/v1/product/enquiry`, data)
}

/**
 *查询询价单列表
 *
 * @export
 */
export function inquireEnquiryApi() {
  return request.get(`/v1/product/enquiry/cart`)
}

/**
 *
 *保存询价单未提交记录
 * @export
 * @param {*} data
 * @return {*}
 */
export function saveNotEnquiryApi(data) {
  return request.post(`/v1/product/enquiry/cart/${data.id}/save`, data)
}

/**
 *删除询价单记录
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function removeEnquiryApi(data) {
  return request.post(`/v1/product/enquiry/cart/${data.id}/delete`, data)
}
