// 导入自己需要的组件
import {
  Button,
  Image,
  Carousel,
  CarouselItem,
  Autocomplete,
  Input,
  Icon,
  Collapse,
  CollapseItem,
  Pagination,
  Loading,
  Divider,
  Select,
  Option,
  Message,
  Dialog,
  Upload,
  Radio,
  RadioButton,
  RadioGroup,
  Badge,
  Tabs,
  TabPane,
  InfiniteScroll,
  Popover
} from 'element-ui'
const element = {
  install: (Vue) => {
    Vue.use(Button)
    Vue.use(Image)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(Autocomplete)
    Vue.use(Input)
    Vue.use(Icon)
    Vue.use(Collapse)
    Vue.use(CollapseItem)
    Vue.use(Pagination)
    Vue.use(Loading)
    Vue.use(Divider)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Dialog)
    Vue.use(Upload)
    Vue.use(Radio)
    Vue.use(RadioGroup)
    Vue.use(Badge)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(RadioButton)
    Vue.use(Popover)
    Vue.use(InfiniteScroll)
    Vue.prototype.$message = Message
  }
}
export default element
