<template>
  <div class="header-nav">
    <head-box />
    <div class="nav-main">
      <flat-nav />
    </div>
  </div>
</template>

<script>
import headBox from './header.vue'
import flatNav from './flatNav.vue'
export default {
  components: {
    headBox,
    flatNav
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.header-nav {
  width: 100%;
  .nav-main {
    position: relative;
  }
}
</style>
