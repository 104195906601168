<template>
  <div class="header">
    <div class="box">
      <div class="left">
        <p
          v-for="item in contact"
          :key="item"
        >{{ item }}</p>
      </div>
      <div class="right">
        <ul class="nav">
          <li
            v-for="(item, index) in nav"
            :key="`${index}${item.title}`"
            :class="{hide:releClass && item.func === 'toRelease'}"
            @click="openFunc(item)"
          >{{ item.title }}</li>
        </ul>
        <div class="user">
          <button
            v-if="!login_user.id"
            class="login-btn"
            @click="toLogin"
          >登录/注册</button>
          <div
            v-else
            class="avatar"
            @click="toPath"
          >
            <el-image
              :src="login_user.avatar"
              class="image"
            />
            <div
              class="menu"
              @click.stop
            >
              <ul>
                <li @click="toPath">我的收藏</li>
                <li @click="toUserCenter">账号管理</li>
                <li @click="logout">退出登录</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mymixins } from '@/mixins/index.js'
import { getToken } from '@/utils/auth.js'
export default {
  mixins: [mymixins],
  data() {
    return {
      banner_url: `${this.$static_domain}/image/banner.png`,
      contact: [
        '+400-6183798',
        'services@zhizhenjujia.cn'
      ],
      nav: [
        {
          title: '联系我们',
          func: 'toContact'
        },
        {
          title: '发布您的产品',
          func: 'toRelease'
        },
        {
          title: '技术支持',
          func: 'toSupport'
        }
      ]
    }
  },
  computed: {
    is_login() {
      if (getToken() && this.login_user.id) return true
      return false
    },
    releClass() {
      let state = false
      if (this.is_login && this.login_user.type === 0 && this.user_info.apply_step === 'not_apply') state = true
      return state
    }
  },
  mounted() {
  },
  methods: {
    toLogin() {
      this.loginViewShow(true)
    },
    toPath() {
      this.$router.push('/usercenter/collect')
    },
    openFunc(item) {
      this[item.func]()
    },
    toContact() {
      this.$router.push('/about')
    },
    toRelease() {
      if (this.is_login) return this.releaseShow(true)
      this.loginViewShow(true)
    },
    toSupport() {
      this.$router.push('/support')
    },
    toUserCenter() { // 账号管理
      this.$router.push('/usercenter')
    },
    logout() { // 退出账号
      this.$store.dispatch('pc/fedLogout').then(() => {
        this.$router.push({ path: `/` })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 50px;
  background-image: url("@{static_url}/image/background-header.png");
  background-color: #fff;
  background-repeat: repeat-x;
  position: relative;
  z-index: 10;
  font-size: 14px;
  .box {
    width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      display: inherit;
      justify-content: flex-start;
      align-items: center;

      p:first-child {
        margin-right: 97px;
      }
    }
    .right {
      display: inherit;
      justify-content: flex-end;
      .nav {
        display: inherit;
        justify-content: flex-start;
        align-items: center;
        li {
          margin-left: 51px;
          cursor: pointer;
          text-transform: uppercase;
          letter-spacing: 0px;
          font-weight: 500;
          &.hide{
            display: none;
          }
        }
        li:hover {
          color: #505050;
        }
      }
      .user {
        display: inherit;
        align-items: center;
        margin-left: 48px;
        position: relative;
        .login-btn {
          width: 169px;
          height: 30px;
          border: none;
          color: #fff;
          background-color: #c09b5a;
          border-radius: 5px;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 25px;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0px;
          cursor: pointer;
          &:hover {
            background-color: #aa884e;
          }
        }
        .avatar {
          width: 40px;
          height: 40px;
          .image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 20px;
            cursor: pointer;
            box-shadow: 0 0 3px #d1d1d1;
            -moz-box-shadow: 0 0 3px #d1d1d1;
            -webkit-box-shadow: 0 0 3px #d1d1d1;
          }
          .menu {
            display: none;
            position: absolute;
            top: 45px;
            right: 0;
            width: 140px;
            padding-top: 8px;
            ul {
              width: 140px;
              height: 200px;
              padding-top: 26px;
              background: #fff;
              border-radius: 6px;
              box-sizing: border-box;
              box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
              li {
                width: 115px;
                height: 32px;
                margin: 0 auto 30px;
                border-radius: 6px;
                background: #f8f4ed;
                box-sizing: border-box;
                border: 1px solid #505050;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          &:hover {
            .menu {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
