
/** @type {*} 设计师设计案例 */
const base_route = import('@/view/index.vue')
export default [
  {
    path: '/case',
    component: () => base_route,
    children: [
      {
        path: '',
        name: 'case',
        meta: {
          title: '设计案例-至臻家居',
          type: 'case',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/case/index.vue')
      },
      {
        path: ':id',
        name: 'case details',
        meta: {
          title: '详情-设计案例-至臻家居',
          type: 'case',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/case/page/details.vue')
      }
    ]
  }
]
