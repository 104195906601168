import store from '@/store'
import msg from '@/utils/toast'

/**
 *
 *登录失效或未登录访问需要登录的路由
 * @export
 * @param {*} next
 */
export function toNoLoginPath(next, status) {
  status && msg.info('登录信息已失效或未登录请重新登录~')
  setTimeout(() => {
    store.dispatch('pc/fedLogout')
    store.dispatch('pc/openLoginView')
  }, 1000)
  next('/')
}
