export default [
  {
    path: '/404',
    component: () => import('@/view/pages/404'),
    hidden: true,
    meta: {
      login: false
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]
