<template>
  <div class="footer-main">
    <div class="top">
      <div class="left">
        <div
          v-for="(item,index) in list"
          :key="index"
          :class="{margin:index === (list.length-1)}"
          class="left-list"
        >
          <h3> {{ item.name }} </h3>
          <ul>
            <li
              v-for="(ele,i) in item.list"
              :key="i"
            >
              <a @click="toPath(ele)">{{ ele.title }}</a>
            </li>
          </ul>
          <div class="_clear" />
        </div>
        <div class="_clear" />
      </div>
      <div class="right">
        <ul>
          <li
            v-for="(item,index) in qr_list"
            :key="index"
          >
            <el-image
              :src="item.image"
              class="qr_image"
            />
            <h3>{{ item.name }}</h3>
          </li>
        </ul>
        <div class="_clear" />
      </div>
      <div class="_clear" />
    </div>
    <div class="bottom">
      <div class="bottom-main">
        <p>Copyright © 2016-2020 台州至臻居家家居有限公司 Taizhou Zhizhen Home Furnishing Co., Ltd.</p>
        <p>

          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
          >浙ICP备2023024497号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { protocol } from '@/assets/public/js/index.js'
export default {
  data() {
    return {
      list: [
        {
          name: '关于我们',
          list: [
            {
              title: '关于我们',
              path: '/about'
            },
            {
              title: '合作伙伴',
              path: '/buddy'
            },
            {
              title: '荣誉奖项',
              path: '/prize'
            }
          ]
        },
        {
          name: '服务支持',
          list: [
            {
              title: '技术支持',
              path: '/support'
            },
            {
              title: '线下展厅',
              path: '/exhibition'
            },
            {
              title: '参赛服务',
              path: '/case?tag'
            }
          ]
        },
        {
          name: '用户须知',
          list: [
            {
              title: '网站协议',
              path: protocol.usage,
              type: 'link'
            },
            {
              title: '用户隐私协议',
              path: protocol.privacy,
              type: 'link'
            }
          ]
        },
        {
          name: '联系我们',
          list: [
            {
              title: '杭州市滨江区智慧之门中心A塔2611',
              path: undefined
            },
            {
              title: '400-6183798',
              path: undefined
            }
          ]
        }
      ],
      qr_list: [
        {
          name: '至臻居家视频号',
          image: `${this.$static_domain}/image/v2/image/qr_video.png`
        },
        {
          name: '至臻居家公众号',
          image: `${this.$static_domain}/image/qr_public.png`
        },
        {
          name: '至臻居家抖音',
          image: `${this.$static_domain}/image/v2/image/qr_douyin.png`
        },
        {
          name: '至臻居家小红书',
          image: `${this.$static_domain}/image/v2/image/qr_xhs.png`
        }
      ]
    }
  },
  methods: {
    toPath(item) {
      if (item.type && item.type === 'link') {
        return window.open(item.path)
      }
      if (item.path) this.$router.push({ path: item.path })
    }
  }
}
</script>

<style lang="less" scoped>
.footer-main {
  width: 100%;
  min-height: 543px;
  background: #f8f4ed;
  .top {
    ._width;
    padding-top: 52px;
    margin-bottom: 65px;
    .left {
      float: left;
      .left-list {
        float: left;
        margin-right: 152px;
        h3 {
          font-size: 24px;
          font-weight: bold;
          line-height: 67px;
          margin-bottom: 35px;
        }
        ul {
          li {
            font-size: 18px;
            line-height: 67px;
            a {
              cursor: pointer;
              &:hover {
                color: #858585;
                border-bottom: 1px solid #858585;
              }
            }
          }
        }
      }
      .margin {
        margin-right: 0;
        ul {
          li {
            a {
              cursor: auto;
              &:hover {
                color: #505050;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .right {
      float: right;
      // padding-top: 69px;
      ul {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 12px 52px;
        li {
          display: inline-block;
          // margin-right: 49px;
          &:last-child {
            margin-right: 0;
          }
          .qr_image {
            width: 133px;
            height: 133px;
            margin-bottom: 10px;
          }
          h3 {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.5;
            text-align: center;
            color: #616161;
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #eae7e2;
    .bottom-main {
      ._width;
      text-align: center;
      padding-top: 19px;
      p {
        font-size: 14px;
        line-height: 14px;
        color: #666666;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        a {
          color: #666666;
          &:hover {
            color: #797878;
            border-bottom: 1px solid #797878;
          }
        }
      }
    }
  }
}
</style>
