import actions from './actions'
import { SessionUtils } from '@/utils/index.js'

export default {
  namespaced: true, // 增加命名空间
  state: {
    assort_nav: SessionUtils.get('assort_nav') || [], // 分类导航列表
    brand_list: SessionUtils.get('brand_list') || [], // 品牌列表
    brand_group_list: SessionUtils.get('brand_group_list') || [], // 品牌分组列表
    competition_list: SessionUtils.get('competition_list') || [], // 资讯 - 赛事服务
    user_info: SessionUtils.get('user_info') || {},
    login_user: {
      apply_step: undefined,
      avatar: undefined
    },
    mask_show: false, // 遮罩
    login_show: false, // 登录窗口
    identity_show: false, // 身份选择窗口
    apply_show: false, // 申请设计师提交窗口
    release_show: false, // 发布作品窗口
    is_apply: false, // 是否是申请设计师发布来源
    inquiry_sheet: {} // 询价单
  },
  getters: {
    assort_nav: state => state.assort_nav, // 分类导航列表
    brand_list: state => state.brand_list, // 品牌列表
    brand_group_list: state => state.brand_group_list, // 品牌列表
    competition_list: state => state.competition_list, // 资讯 - 赛事服务
    user_info: state => state.user_info, // 用户信息
    login_user: state => state.login_user, // 登录用户信息
    mask_show: state => state.mask_show, // 遮罩
    login_show: state => state.login_show, // 登录窗口
    identity_show: state => state.identity_show, // 身份选择窗口
    apply_show: state => state.apply_show, // 申请设计师提交窗口
    release_show: state => state.release_show, // 发布作品窗口
    is_apply: state => state.is_apply, // 是否是申请设计师发布来源
    inquiry_sheet: state => state.inquiry_sheet // 询价单
  },
  mutations: {
    ASSORT_NAV(state, data) { // 分类导航列表
      state.assort_nav = data
      SessionUtils.set('assort_nav', state.assort_nav)
    },
    BRAND_LIST(state, data) { // 品牌列表
      state.brand_list = data
      SessionUtils.set('brand_list', state.brand_list)
    },
    BRAND_GROUP_LIST(state, data) { // 品牌分组列表
      state.brand_group_list = data
      SessionUtils.set('brand_group_list', state.brand_group_list)
    },
    COMPETITION_LIST(state, data) { // 资讯 - 赛事服务
      state.competition_list = data
      SessionUtils.set('competition_list', state.competition_list)
    },
    USER_INFO(state, data) {
      SessionUtils.set('user_info', data)
      data.avatar = data.avatar || `${process.env.VUE_APP_STATIC_URL}/image/icon_avatar.png`
      state.user_info = data
    },
    LOGIN_USER(state, data) {
      data.avatar = data.avatar || `${process.env.VUE_APP_STATIC_URL}/image/icon_avatar.png`
      state.login_user = data
    },
    MASK_SHOW(state, data) {
      state.mask_show = data
    },
    LOGIN_SHOW(state, data) {
      state.login_show = data
    },
    IDENTITY_SHOW(state, data) {
      state.identity_show = data
    },
    APPLY_SHOW(state, data) {
      state.apply_show = data
    },
    RELEASE_SHOW(state, data) {
      state.release_show = data
    },
    IS_APPLY(state, data) {
      state.is_apply = data
    },
    INQUIRY_SHEET(state, data) {
      state.inquiry_sheet = data
    }
  },
  actions
}
