<template>
  <div class="nav-roll">
    <transition name="el-fade-in">
      <div
        v-show="show"
        :style="head_style"
        class="roll-box"
      >
        <div class="head-box">
          <headBox />
        </div>
        <!-- <flatNav /> -->
      </div>
    </transition>
  </div>
</template>

<script>
import headBox from './header.vue'
import flatNav from './flatNav.vue'
export default {
  components: {
    headBox,
    flatNav
  },
  props: {
    distance: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      show: false,
      head_style: {
        top: '-50px'
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrolling)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolling)
  },
  methods: {
    navShow() {
      this.show = true
    },
    navHide() {
      this.show = false
    },
    headShow() {
      this.head_style = {
        top: '0'
      }
    },
    headHide() {
      this.head_style = {
        top: '-50px'
      }
    },
    scrolling() {
      // 滚动条距文档顶部的距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 滚动条滚动的距离
      const scrollStep = scrollTop - this.oldScrollTop
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop

      // 变量windowHeight是可视区的高度
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight

      // 滚动条到底部的条件
      // eslint-disable-next-line
      if (scrollTop + windowHeight == scrollHeight) {
        // 已经到底部了
      }
      if (scrollStep < 0) {
        this.headShow()
        if (scrollTop <= this.distance) this.navHide() // 滚到首屏时移除悬浮导航
      } else {
        if (scrollTop > this.distance) this.navShow() // 滚动大于1000 显示top 导航
        this.headHide()
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        // 顶部
      }
    }
  }
}
</script>
<style lang="less">
.nav-roll {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s;
  .roll-box {
    transition: all 0.09s;
    position: relative;
  }
}
</style>
