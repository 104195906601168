// mobile 首页和品牌详情
const base_route = import('@/view/index.vue')
export default [
  {
    path: '/',
    component: () => base_route,
    children: [
      {
        path: '',
        name: 'home',
        meta: {
          title: '至臻居家',
          type: 'home',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/home')
      },
      {
        path: 'brand/:id',
        component: () => import('@/view/pages/brandIntro/index.vue'),
        children: [
          {
            path: '',
            name: 'intro',
            meta: {
              title: '概览-品牌-至臻居家',
              type: 'intro',
              login: false,
              keepAlive: false
            },
            component: () => import('@/view/pages/brandIntro/page/intro')
          },
          {
            path: 'product',
            name: 'brand intro product',
            meta: {
              title: '产品-品牌-至臻居家',
              type: 'product',
              keepAlive: false,
              login: false,
              scroll: 'auto'
            },
            component: () => import('@/view/pages/brandIntro/page/product')
          },
          {
            path: 'manual',
            name: 'brand manual',
            meta: {
              title: '手册-品牌-至臻居家',
              type: 'manual',
              keepAlive: false,
              login: false,
              scroll: 'auto'
            },
            component: () => import('@/view/pages/brandIntro/page/manual.vue')
          }
        ]
      },
      {
        path: 'brand-list',
        name: 'brandList',
        meta: {
          title: '品牌列表-至臻居家',
          type: 'brand',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/brandList/index.vue')
      },
      {
        path: 'we-media',
        name: 'weMedia',
        meta: {
          title: '视频列表-至臻居家',
          type: 'brand',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/weMedia/index.vue')
      },
      {
        path: 'exhibition',
        name: 'exhibition',
        meta: {
          title: '线下展厅-至臻居家',
          type: 'exhibition',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/exhibition.vue')
      },
      {
        path: 'support',
        name: 'support',
        meta: {
          title: '技术支持-至臻居家',
          type: 'support',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/support.vue')
      },
      {
        path: 'buddy',
        name: 'buddy',
        meta: {
          title: '合作伙伴-至臻居家',
          type: 'buddy',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/buddy.vue')
      },
      {
        path: 'about',
        name: 'about',
        meta: {
          title: '关于我们-至臻居家',
          type: 'about',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/about.vue')
      },
      {
        path: 'prize',
        name: 'prize',
        meta: {
          title: '荣誉奖项-至臻居家',
          type: 'prize',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/prize.vue')
      }
    ]
  }
]
